import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PersonalServiceSidebar from "../components/Repeating/PersonalServiceSidebar";
import PersonalServiceSlider from "../components/Repeating/PersonalServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Crypto Consulting Services | DC, MD & VA | DeBlanc"
        description="Build your knowledge about cryptocurrency and a solid investment plan. Call DeBlanc + Murphy for crypto consulting services in the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Crypto Consulting Services</h1>
            <p className="mb-0 md:text-xl">
              New to cryptocurrency? Already investing? We’ll teach you and
              guide you on the best crypto strategies. Serving the greater
              Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Cryptoasset Taxation and Advisory Services</h2>
                <p>
                Cryptocurrency is a confusing topic for some, but not for us. We're experts in cryptocurrency investing and cryptoasset taxation and have been in this space since 2015. 
                </p>
                <p>Since then, we've consulted with top crypto exchanges, initial coin offerings, layer-one developers, businesses transacting in crypto, and individual crypto traders. </p>

                <p>We've helped clients navigate crypto compensation issues, including Restricted Token Units (RTUs), Nonqualified Token Option, and Incentive Token Options. </p>

                <p>We've worked with everyone in this space, from crypto whales to novice investors. Whether you're just getting started or you've been here for a while, we can help you navigate the cryptosphere:</p>

                <ul className="styled-list-plus alt mb-6">
                  <li>We can help you get started investing and transacting in crypto</li>
                  <li>We help build and advise on a portfolio of cryptoassets and integrate that into your overall diversified portfolio</li>
                  <li>We can help you strategically divest and diversify from large, consolidated crypto positions in a tax-efficient manner</li>
                  <li>We can advise on and help facilitate charitable contributions of cryptoassets</li>
                  <li>We can be your trusted crypto advisor to handle all your tax and advisory needs
                  </li>
                </ul>


                <ButtonSolid modal="modal-contact" altStyle={2} text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <PersonalServiceSidebar activeService={2} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <PersonalServiceSlider hideService={2} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "3.3 Crypto Consulting/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
