import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "gatsby";

import SliderServices from "../../components/Slider/SliderServices";

const BusinessServiceSlider = ({ hideService }) => {
  const data = useStaticQuery(graphql`
    {
      personalTaxPrep: file(
        relativePath: {
          eq: "3.0 Personal/1.1 Personal Tax Prep _ Planning.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      estateTrust: file(
        relativePath: { eq: "3.0 Personal/1.2 Estate _ Trust Planning.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      crypto: file(
        relativePath: { eq: "3.0 Personal/1.3 Crypto Consulting.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      familyBusiness: file(
        relativePath: { eq: "3.0 Personal/1.4 Family Business.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      wealthManagement: file(
        relativePath: { eq: "3.0 Personal/1.5 IRS Representation.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      irsRepresentation: file(
        relativePath: { eq: "3.0 Personal/1.6 Wealth Management.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
    }
  `);

  const sliderContent = [
    {
      image: data.personalTaxPrep.childImageSharp.gatsbyImageData,
      title: "Personal Tax Prep & Planning",
      text: "Get help preparing your taxes and putting smart tax strategies in place for your future.",
      href: "/personal-tax-preparation-planning-services/",
    },
    {
      image: data.estateTrust.childImageSharp.gatsbyImageData,
      title: "Estate & Trust Planning",
      text: "Provide security for your family and protect your property with estate and trust planning.",
      href: "/estate-trust-planning-services/",
    },
    {
      image: data.crypto.childImageSharp.gatsbyImageData,
      title: "Crypto Consulting",
      text: "Learn how to incorporate cryptocurrency investments into your big-picture finances.",
      href: "/crypto-consulting-services/",
    },
    {
      image: data.familyBusiness.childImageSharp.gatsbyImageData,
      title: "Family Business",
      text: "Get help reaching financial goals while balancing the complex dynamics of a family business.",
      href: "/family-owned-business-cpa/",
    },
    {
      image: data.irsRepresentation.childImageSharp.gatsbyImageData,
      title: "IRS Representation",
      text: "Are you worried about the IRS? Don’t panic. As your representative, we can handle all communications with them.",
      href: "/irs-representation-services/",
    },
    {
      image: data.wealthManagement.childImageSharp.gatsbyImageData,
      title: "Wealth Management",
      text: "Take steps to safeguard your assets, ensure financial stability and reach your goals.",
      href: "/wealth-management-services/",
    },
  ];

  return (
    <section className="overflow-x-hidden bg-secondary-100 pt-18 pb-24">
      <div className="container relative">
        <div className="absolute left-0 top-0 z-10 hidden h-full w-full -translate-x-full transform bg-secondary-100 md:block"></div>
        <header className="mb-10 md:mb-14">
          <h2 className="text-gray-700">
            We Also Offer These Personal Services
          </h2>
        </header>

        <SliderServices>
          {sliderContent.map(
            (item, i) =>
              i !== hideService && (
                <div key={i}>
                  <Link to={item.href} className="group no-underline">
                    <div className="-mx-4 mb-3.5 overflow-hidden md:mx-0">
                      <GatsbyImage
                        image={item.image}
                        className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="heading-five mb-3 text-gray-700">
                      {item.title}
                    </div>
                    <p className="mb-0 font-normal text-gray-500">
                      {item.text}
                    </p>
                  </Link>
                </div>
              )
          )}
        </SliderServices>
      </div>
    </section>
  );
};

export default BusinessServiceSlider;
